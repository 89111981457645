import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { Link } from 'gatsby'
import { IntlProvider } from 'contexts/intl/IntlContext'

function Index({ location }) {
  useEffect(() => {
    const hr = document.querySelector('#hr')
    const mn = document.querySelector('#mn')
    const sc = document.querySelector('#sc')
    const deg = 6
    const updateClock = () => {
      let day = new Date()
      let hh = day.getHours() * 30
      let mm = day.getMinutes() * deg
      let ss = day.getSeconds() * deg
      hr.style.transform = `rotate(${hh + mm / 12}deg)`
      mn.style.transform = `rotate(${mm}deg)`
      sc.style.transform = `rotate(${ss}deg)`
    }
    const interval = setInterval(updateClock, 1000)
    updateClock() // initial call to set the clock immediately

    return () => clearInterval(interval) // cleanup on unmount
  }, [])
  return (
    <IntlProvider pathname={location.pathname}>
      {({ locale, formatMessage, getPath }) => (
        <Layout>
          <SEO
            lang={locale}
            title={formatMessage({ id: 'analog.clock.title' })}
            description={formatMessage({ id: 'meta.analog.clock.description' })}
            keywords={formatMessage({ id: 'meta.analog.clock.keywords' })}
            url={formatMessage({ id: 'meta.analog.clock.url' })}
            isBlogPost={false}
            ogTitle={formatMessage({ id: 'analog.clock.title' })}
            ogUrl={formatMessage({ id: 'meta.analog.clock.url' })}
            ogDescription={formatMessage({ id: 'meta.analog.clock.description' })}
            ogKeywords={formatMessage({ id: 'meta.analog.clock.keywords' })}
            ogLocale={locale}
            twitterTitle={formatMessage({ id: 'analog.clock.title' })}
            twitterDescription={formatMessage({ id: 'meta.analog.clock.description' })}
            twitterCreator={'Mihir Pipermitwala'}
            siteName={'Mihir Pipermitwala'}
          />
          <div className='container'>
            <div className='is-mobile'>
              <div className='mb-12'>
                <h1 className='is-size-1 has-text-weight-bold has-text-centered has-text-white'>Analog Clock</h1>
              </div>
              <div className='columns is-full ml-1 mr-1 mt-6'>
                <div className='column is-full clock-container'>
                  <div className='control'>
                    <div class='clock'>
                      <div class='hour'>
                        <div class='hr' id='hr'></div>
                      </div>
                      <div class='min'>
                        <div class='mn' id='mn'></div>
                      </div>
                      <div class='sec'>
                        <div class='sc' id='sc'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-6'>
            <p className='has-text-centered'>
              {formatMessage({ id: 'checkOut.tools' })}{' '}
              <Link to={getPath('tools')}>{formatMessage({ id: 'tools.title' })}</Link>
            </p>
          </div>
        </Layout>
      )}
    </IntlProvider>
  )
}

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Index
